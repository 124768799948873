import React from 'react';
import './landing.scss';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

class Landing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        return (
            <div className='d-flex flex-column landing'>
                <div className='d-flex flex-column align-items-center flex-grow-1'>
                    <div className='d-flex flex-column align-items-center justify-content-center flex-grow-1'>
                        <div className='landing-logo'>
                            <div className='d-flex align-items-center justify-content-center'>
                                <img
                                    className='auth-logo'
                                    src={require('../../../assets/logo.svg')}
                                    alt=''
                                />
                            </div>
                            {/* <div className='d-flex align-items-center justify-content-center description'>
                                MAKE AN IMPACT
                            </div> */}

                        </div>

                        <div className='landing-form'>
                            <div className='d-flex align-items-center justify-content-center'>
                                <Button
                                    type='button'
                                    className='btn btn-primary signup-btn mr-2'
                                    onClick={() => this.props.history.push('/signup')}
                                >
                                    SIGN UP
                                </Button>
                                <Button
                                    type='button'
                                    variant='outline-light'
                                    className='btn login-btn'
                                    onClick={() => this.props.history.push('/login')}
                                >
                                    LOGIN
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='d-flex flex-column flex-lg-row align-items-center justify-content-sm-center justify-content-lg-start footer'>
                    <div className='d-flex align-self-stretch align-items-center justify-content-sm-center justify-content-lg-start footer-section'>
                        <Link to={'#'} onClick={(e) => {
                            window.location.href = 'https://assembly.us';
                            e.preventDefault();
                        }} className='align-self-stretch items'>HOME</Link>
                        <Link to={'/terms'} className='align-self-stretch items'>TERMS</Link>
                        <Link to={'/privacy-policy'} className='align-self-stretch items'>PRIVACY</Link>
                    </div>
                    <div className='d-flex align-self-stretch align-items-center justify-content-sm-center justify-content-lg-start footer-section'>
                        <Link to={'/support'} className='align-self-stretch items'>SUPPORT</Link>
                        <Link to={'#'} onClick={(e) => {
                            window.location.href = 'mailto:info@assembly.us';
                            e.preventDefault();
                        }} className='align-self-stretch items'>CONTACT</Link>
                    </div>

                </div>

                {/* <div className='background-overlay'></div> */}
            </div>
        );
    }
}

export default Landing;
