import React from 'react';
import './styles.scss';
import { Link } from 'react-router-dom';

const Support = (props) => {
    const goBack = (event) => {
        event.preventDefault();
        props.history.goBack();
    }
    return (
        <div>
            <style dangerouslySetInnerHTML={{ __html: "\n        [data-custom-class='body'],\n        [data-custom-class='body'] * {\n                        }\n\n        [data-custom-class='title'],\n        [data-custom-class='title'] * {\n                font-family: Arial !important;\n                font-size: 26px !important;\n                color: #ffffff !important;\n        }\n\n        [data-custom-class='subtitle'],\n        [data-custom-class='subtitle'] * {\n                font-family: Arial !important;\n                color: #ffffff !important;\n                font-size: 14px !important;\n        }\n\n        [data-custom-class='heading_1'],\n        [data-custom-class='heading_1'] * {\n                font-family: Arial !important;\n                font-size: 19px !important;\n                color: #bbbdbf !important;\n        }\n\n        [data-custom-class='heading_2'],\n        [data-custom-class='heading_2'] * {\n                font-family: Arial !important;\n                font-size: 17px !important;\n                color: #bbbdbf !important;\n        }\n\n        [data-custom-class='body_text'],\n        [data-custom-class='body_text'] * {\n                color: #ffffff !important;\n                font-size: 14px !important;\n                font-family: Arial !important;\n        }\n\n        [data-custom-class='link'],\n        [data-custom-class='link'] * {\n                color: #3030F1 !important;\n                font-size: 14px !important;\n                font-family: Arial !important;\n                word-break: break-word !important;\n        }\n" }} />
            <div className='support p-1'>
                <div className='d-flex align-items-center justify-content-left'>
                    <img
                        className='auth-logo'
                        src={require('../../../assets/logo.svg')}
                        alt=''
                        onClick={() => props.history.push('/login')}
                    />
                </div>
                <div align="center" style={{ textAlign: 'left', lineHeight: 1 }}>
                    <div align="center" className="MsoNormal" style={{ textAlign: 'left', lineHeight: '150%' }}>
                        <div align="center" className="MsoNormal" style={{ textAlign: 'center', lineHeight: '150%' }}><a name="_2cipo4yr3w5d" />
                            <div align="center" className="MsoNormal" style={{ lineHeight: '22.5px' }}>
                                <div align="center" className="MsoNormal" style={{ lineHeight: '150%' }}><a name="_gm5sejt4p02f" />
                                    <div align="center" className="MsoNormal" data-custom-class="title" style={{ textAlign: 'center', lineHeight: '1.5' }}>
                                        <strong>
                                            <span style={{ lineHeight: '22.5px', fontSize: '26px' }}>
                                                <bdt className="block-component" />
                                                <bdt className="question">SUPPORT</bdt>
                                                <bdt className="statement-end-if-in-editor" />
                                            </span>
                                        </strong>
                                    </div>
                                    <div className='d-flex align-items-center justify-content-center mt-4 join-now'>
                                        <Link to='#' onClick={goBack} className='join-now-link'>
                                            <span>{'< '}GO BACK</span>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div align="center" className="MsoNormal" style={{ lineHeight: '17.25px', textAlign: 'left' }}>
                                <br />
                            </div>
                            <div align="center" className="MsoNormal" style={{ lineHeight: '17.25px', textAlign: 'left' }}>
                                <br />
                            </div>
                            <div align="center" className="MsoNormal" style={{ lineHeight: '17.25px', textAlign: 'left' }}>
                                <br />
                            </div>
                            <div align="center" style={{ textAlign: 'left', lineHeight: 1 }}><br /></div>
                        </div>
                        <strong>
                            <strong>
                                <div style={{ color: '#595959', fontSize: '14px', fontFamily: 'Arial', paddingTop: '16px', textAlign: 'center' }}>
                                    For support please email <a style={{ color: 'rgb(48, 48, 241) !important' }}>
                                        <Link to={'#'} onClick={(e) => {
                                            window.location.href = 'mailto:help@assembly.us';
                                            e.preventDefault();
                                        }} className='align-self-stretch items'>help@assembly.us</Link>
                                    </a>.
                                </div>
                            </strong>
                        </strong>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Support;