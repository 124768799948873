import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { Form, Button, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import adminApi from '../../../services/admin.service';
import session from '../../../services/session.service';
import helper from '../../../services/helper.service';
import './styles.scss';

const month = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

const ClubCard = ({ club }) => {
    const joinClub = async () => {
        session.set('futureof-club', club);
        if (club.user_role === 'manager') {
            window.location.href = '/manager';
        } else if (club.user_role === 'user') {
            window.location.href = '/user';
        }
    };

    let d = new Date(club.connected_at);
    let connected_at = `${month[d.getMonth()]} ${d.getFullYear()}`;

    return (
        <div className='club-card pb-4'>
            <Col className='justify-content-md-start club-wrapper'>
                <Row>
                    <Col
                        className='club-container'
                        style={{
                            backgroundImage: `url(${club?.banner_url})`,
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            flexDirection: 'column',
                            borderTopLeftRadius: 6,
                            // borderTopRightRadius: 6,
                        }}
                    >
                        {/* <div className='club-name'>#{club?.club_name}</div> */}
                        <span
                            style={{
                                textAlign: 'center',
                                backgroundColor: 'gray',
                                width: '100%',
                                paddingRight: 10,
                                paddingLeft: 10,
                                display: 'block',
                                wordWrap: 'break-word',
                                wordBreak: 'break-word',
                                whiteSpace: 'pre-line',
                                position: 'absolute',
                                bottom: 0,
                                opacity: 0.7,
                                textTransform: 'uppercase'
                            }}
                        >
                            {club.user_role === 'user' ? `Member since` : `Club Manager since`} {connected_at}
                        </span>
                    </Col>
                    <Col
                        sm={{ span: 3 }}
                        className='d-flex align-items-center justify-content-center'
                    >
                        <Button
                            className='btn btn-primary join'
                            size='sm'
                            onClick={joinClub}
                            block
                        >
                            ENTER
                        </Button>
                    </Col>
                </Row>
            </Col>
        </div>
    );
};

const SwitchClubs = ({ onBack }) => {
    const history = useHistory();
    const user = session.get('futureof-user');
    const [clubs, setClubs] = useState([]);
    useEffect(() => {
        getClubs();
    }, []);

    const getClubs = async () => {
        try {
            const userRes = await adminApi.getClubsWithUserId(user.user_id);
            if (
                userRes.status &&
                Array.isArray(userRes.connect) &&
                userRes.connect.length > 0
            ) {
                setClubs(userRes.connect);
            } else {
                helper.showToast(
                    'Error',
                    'There is no club for which you are member',
                    3
                );
            }
        } catch (error) {
            console.log('getClubs error: ', error);
            helper.showToast('Error', 'Failed to get clubs.', 3);
        }
    };

    const goBack = (event) => {
        event.preventDefault();
        history.goBack();
        onBack(false);
    };

    return (
        <div className='select-clubs overflow-hidden'>
            <div className='d-flex align-content-center justify-content-start logo'>
                <img
                    className='auth-logo'
                    src={require('../../../assets/logo.svg')}
                    alt=''
                />
            </div>
            <div className='d-flex align-content-center justify-content-center'>
                <Form
                    className='signup-form'
                    style={{ maxWidth: '800px', width: '800px' }}
                >
                    <h5 className='d-flex align-items-center justify-content-center text-center mt-2 mb-4 title'>
                        ENTER A CLUB
                    </h5>
                    <div
                        className='d-flex align-items-center justify-content-center mt-2 p-2 border border-white rounded'
                        style={{ height: '500px', overflowY: 'auto' }}
                    >
                        {clubs && clubs.length > 0 && (
                            <div
                                style={{
                                    display: 'block',
                                    width: '100%',
                                    height: '100%',
                                }}
                            >
                                {clubs.map((club) => {
                                    return (
                                        <ClubCard
                                            key={club.club_id}
                                            club={club}
                                        />
                                    );
                                })}
                            </div>
                        )}
                    </div>

                    <div className='d-flex align-items-center justify-content-center mt-4 join-now'>
                        <Link to='#' onClick={goBack} className='join-now-link'>
                            <span>{'< '}Go Back</span>
                        </Link>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default SwitchClubs;
