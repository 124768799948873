import React from 'react';
// API
import session from '../services/session.service';

import GuestRouter from './Guest';
import UserRouter from './User';
import ManagerRouter from './Manager';
import AdminRouter from './Admin';

class Admin extends React.Component {
    constructor(props) {
        super();
    }

    render() {
        const user = session.get('futureof-user');
        const currentClub = session.get('futureof-club');
        if (user) {
            if (user.user_role === 'admin') {
                return <AdminRouter />;
            } else if (currentClub.user_role === 'manager') {
                return <ManagerRouter />;
            } else if (currentClub.user_role === 'user') {
                return <UserRouter />;
            }
        } else {
            return <GuestRouter />;
        }
    }
}

export default Admin;
