import React from 'react';
import '../tbl-clubs.scss';

import { Form, Modal, Button } from 'react-bootstrap';

import adminApi from '../../../services/admin.service';
import helper from '../../../services/helper.service';

import { user as DataModel } from '../Model';

import Header from '../components/header';
import TableComp from '../components/table';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import CreateUser from './createUser';

const model_keys = Object.keys(DataModel);
const filter_keys = model_keys.filter(
    (r) => DataModel[r] === 'String' && !`${r}`.includes('_url')
);
const link_keys = ['user_id', 'club_id'];
const link_paths = [
    '/database/connect/users/',
    '/database/connect/clubs/',
    '/database/connect/clubs/users/',
];
const main_key = 'user_id';
const edit_keys = ['user_role'];
class Users extends React.Component {
    constructor(props) {
        super();
        this.state = {
            data_list: [],
            loading: true,
            search: '',
            filterItem: filter_keys[0],
            sorted: {},
            showModal: false,
            deleting: false,
            edit_id: '',
            del_id: '',
            user_role: 'user',
            editied_key: '',
            showAddUserModal: false,
            clubs: [],
            filtered: [],
        };
    }

    componentDidMount() {
        this.getAllUsers();
    }

    getAllUsers = () => {
        adminApi
            .getAllUsers()
            .then((response) => {
                if (response.status === true) {
                    var results = response.data;
                    const users = results.filter(item => item.user_id);
                    this.getClubsByUsers(users);
                    this.getList(users);
                }
            })
            .catch((error) => {
                console.log('err: ', error);
                this.setState({
                    loading: false,
                });
            });
    };

    getClubsByUsers = async (users) => {
        try {
            var clubs = [];
            await Promise.all(
                users.map(async (user) => {
                    const response = await adminApi.getClubsWithUserId(
                        user.user_id
                    );
                    if (response.status) {
                        let results = response.connect;
                        results = results.map(result => {
                            result.user_id = user.user_id
                            return result;
                        });
                        clubs = [...clubs, ...results];
                        user.user_role = results.map(
                            (result) => `${result.club_name} (${result.user_role ? result.user_role : ''})`
                        );
                    }
                })
            );
            this.setState({
                clubs,
                loading: false,
            });
        } catch (error) {
            this.setState({
                loading: false,
            });
            helper.showToast('Error', error.message, 3);
        }

    };

    getList = async (users) => {
        try {
            const response = await adminApi.getData();
            if (response.status) {
                let results = response.data;
                console.log('results: ', results);
                const data_list = users.map(user => {
                    let audio_received_list = results.filter(
                        (a) => a.audio_id && a.audio_id !== '' && a.host_id === user.user_id
                    );
                    let post_received_list = results.filter(
                        (a) => a.post_id && a.post_id !== '' && a.host_id === user.user_id
                    );
                    let room_received_list = results.filter(
                        (a) => a.assemble_id && a.assemble_id !== '' && a.host_id === user.user_id
                    );
                    let audio_given_list = results.filter(
                        (a) => {
                            if (a.user_id) {
                                return a.audio_id && a.audio_id !== '' && a.user_id === user.user_id
                            } else {
                                return a.audio_id && a.audio_id !== '' && a.user.user_id === user.user_id
                            }
                        }
                    );
                    let post_given_list = results.filter(
                        (a) => {
                            if (a.user_id) {
                                return a.audio_id && a.audio_id !== '' && a.user_id === user.user_id
                            } else {
                                return a.audio_id && a.audio_id !== '' && a.user.user_id === user.user_id
                            }
                        }
                    );
                    let room_given_list = results.filter(
                        (a) => {
                            if (a.user_id) {
                                return a.audio_id && a.audio_id !== '' && a.user_id === user.user_id
                            } else {
                                return a.audio_id && a.audio_id !== '' && a.user.user_id === user.user_id
                            }
                        }
                    );
                    const likesReceivedAudios = audio_received_list.map(data => data.likes_gained).reduce((partialSum, a) => partialSum + a, 0);
                    const likesReceivedPosts = post_received_list.map(data => Number(data.likes_gained || 0)).reduce((partialSum, a) => partialSum + a, 0);
                    const likesReceivedRooms = room_received_list.map(data => Number(data.likes_gained || 0)).reduce((partialSum, a) => partialSum + a, 0);
                    const likesGivenAudios = audio_given_list.map(data => data.likes_gained).reduce((partialSum, a) => partialSum + a, 0);
                    const likesGivenPosts = post_given_list.map(data => Number(data.likes_gained || 0)).reduce((partialSum, a) => partialSum + a, 0);
                    const likesGivenRooms = room_given_list.map(data => Number(data.likes_gained || 0)).reduce((partialSum, a) => partialSum + a, 0);
                    console.log('likesReceivedAudios: ', likesReceivedAudios);
                    user.Likes_Posts_Received = likesReceivedPosts;
                    user.Likes_Audios_Received = likesReceivedAudios;
                    user.Likes_Rooms_Received = likesReceivedRooms;
                    user.Likes_Posts_Given = likesGivenPosts;
                    user.Likes_Audios_Given = likesGivenAudios;
                    user.Likes_Rooms_Given = likesGivenRooms;
                    return user;
                });
                this.setState({ data_list });
            }
        } catch (error) {
            helper.showToast('Error', error.message, 3);
        }
    };

    handleChangeFilter = (event) => {
        const filterItem = event.target.value;
        this.setState({
            filterItem,
        });
    };

    handleChangeSearch = (event) => {
        const search = event.target.value;
        this.setState({
            search,
        });
    };

    handleUpdateTableItem = async (event) => {
        const { clubs } = this.state;
        const key = event.target.getAttribute('name');
        // const value = event.target.dataset.recordId;
        const edit_id = event.target.id;

        if (edit_id) {
            const filtered = clubs.filter(club => club.user_id === edit_id);
            this.setState({
                club_id: filtered && filtered.length > 0 ? filtered[0].club_id : '',
                filtered: filtered,
                edit_id,
                editied_key: key
            });

            // this.setState({
            //     edit_id,
            //     [key]: value,
            //     editied_key: key,
            // });
            this.handleShowModal();
        }
    };
    handleChangeModalInput = (event) => {
        const key = event.target.getAttribute('name');
        const value = event.target.value;
        console.log(key, value);
        if (value !== '') {
            this.setState({
                [key]: event.target.value.toLowerCase(),
            });
        } else {
            helper.showToast('Warning', 'Please fill out the field', 2);
        }
    };
    handleSubmitModal = async () => {
        const { data_list, edit_id, editied_key, club_id } = this.state;
        const value = this.state[editied_key];
        this.handleShowModal();
        console.log('handleSubmitModal1: ', edit_id, value, club_id);

        if (edit_id !== '' && value !== '' && club_id !== '') {
            const obj = {};
            obj[editied_key] = value;
            obj['club_id'] = club_id;
            obj['user_id'] = edit_id
            console.log('Ok', obj);

            try {
                const updateResult = await adminApi.updateConnectClub(obj);
                console.log('updateResult: ', updateResult);
                if (updateResult.status) {
                    const find_index = data_list.findIndex(
                        (r) => r.user_id === updateResult.data.user_id
                    );
                    if (find_index !== -1) {
                        // data_list[find_index] = updateResult.data;
                        this.getAllUsers();
                    }
                    helper.showToast('Success', 'User Role updated successfully', 1);
                } else {
                    helper.showToast('Error', 'User Role failed to update', 3);
                }
            } catch (error) {
                console.error(error);
                helper.showToast('Error', error.message, 3);
            }
        }

        // if (edit_id !== '' && value !== '') {
        //     if (value === 'admin' || value === 'user' || value === 'manager') {
        //         console.log('Ok');
        //         const obj = {};
        //         obj[editied_key] = value;
        //         adminApi
        //             .updateUserWithId(edit_id, obj)
        //             .then((response) => {
        //                 if (response.status === true) {
        //                     console.log(JSON.stringify(response.data));
        //                     var result = response.data;
        //                     const find_index = data_list.findIndex(
        //                         (r) => r.user_id === result.user_id
        //                     );
        //                     if (find_index !== -1) {
        //                         data_list[find_index] = result;
        //                         this.setState({ data_list });
        //                     }
        //                 }
        //             })
        //             .catch((error) => {
        //                 console.log('err: ', error);
        //                 this.setState({
        //                     loading: false,
        //                 });
        //             });
        //     } else {
        //         helper.showToast(
        //             'Warning',
        //             'Please input one of them Admin or User',
        //             2
        //         );
        //     }
        // }
    };

    handleShowModal = () => {
        this.setState({
            showModal: !this.state.showModal,
        });
    };

    handleShowAddUserModal = () => {
        this.setState({
            showAddUserModal: !this.state.showAddUserModal,
        });
    };

    deleteUser = (del_id) => {
        console.log('del_id', del_id);
        if (del_id !== '') {
            adminApi
                .deleteUser(del_id)
                .then((response) => {
                    if (response.status === true) {
                        this.setState({
                            data_list: this.state.data_list.filter(
                                (data) => data.user_id !== del_id
                            ),
                        });
                        helper.showToast(
                            'Success',
                            'User deleted successfully',
                            1
                        );
                    } else {
                        helper.showToast('Error', response.data, 3);
                    }
                })
                .catch((error) => {
                    console.log('err: ', error);
                    helper.showToast(
                        'Error',
                        error.message ||
                        'There was an error while deleting the user info.',
                        3
                    );
                });
        }
    };

    onDeleteBulk = async (delIds, callBack = () => { }) => {
        const promiseList = [];
        for (const del_id of delIds) {
            promiseList.push(adminApi.deleteUser(del_id));
        }
        await Promise.all(promiseList);
        this.getAllUsers();
        callBack();
    };

    render() {
        const {
            search,
            filterItem,
            data_list,
            loading,
            showModal,
            editied_key,
            filtered
        } = this.state;
        const modalTitle = 'Choose user type';
        return (
            <div className='tbl-clus'>
                <div className='content'>
                    <Header
                        title='Users'
                        filterItem={filterItem}
                        filter_keys={filter_keys}
                        handleChangeFilter={this.handleChangeFilter}
                        handleChangeSearch={this.handleChangeSearch}
                    />
                    <Button
                        style={{ marginBottom: 16 }}
                        onClick={this.handleShowAddUserModal}
                    >
                        <FontAwesomeIcon icon={faPlusCircle} /> ADD USER
                    </Button>
                    <TableComp
                        data_list={data_list}
                        dataModel={
                            {
                                ...DataModel,
                                Likes_Posts_Given: 'Number', Likes_Posts_Received: 'Number',
                                Likes_Audios_Given: 'Number', Likes_Audios_Received: 'Number',
                                Likes_Rooms_Given: 'Number', Likes_Rooms_Received: 'Number'
                            }
                        }
                        filterItem={filterItem}
                        search={search}
                        loading={loading}
                        main_key={main_key}
                        edit_keys={edit_keys}
                        link_keys={link_keys}
                        link_paths={link_paths}
                        perPage={5}
                        onChangeStrValue={this.handleUpdateTableItem}
                        onDeleteItem={this.deleteUser}
                        onDeleteBulk={this.onDeleteBulk}
                    />
                    <Modal
                        show={showModal}
                        size='sm'
                        aria-labelledby='contained-modal-title-vcenter'
                        centered
                        onHide={this.handleShowModal}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {modalTitle}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form.Control
                                as='select'
                                name='club_id'
                                onChange={this.handleChangeModalInput}
                            >
                                {
                                    filtered.map(club => {
                                        return (
                                            <option key={club.club_id} value={club.club_id}>
                                                {club.club_name}
                                            </option>
                                        )
                                    })
                                }
                            </Form.Control>
                            <Form.Control
                                as='select'
                                name={editied_key}
                                defaultValue={this.state[editied_key]}
                                onChange={this.handleChangeModalInput}
                                className='mt-2'
                            >
                                <option key='user' value='user'>
                                    user
                                </option>
                                <option key='manager' value='manager'>
                                    manager
                                </option>
                                {/* <option key='admin' value='admin'>
                                    admin
                                </option> */}
                            </Form.Control>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant='secondary'
                                onClick={this.handleShowModal}
                            >
                                Close
                            </Button>
                            <Button
                                variant='primary'
                                onClick={this.handleSubmitModal}
                            >
                                Save
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <CreateUser
                        showModal={this.state.showAddUserModal}
                        handleShowModal={this.handleShowAddUserModal}
                        getAllUsers={this.getAllUsers}
                    />
                </div>
            </div>
        );
    }
}

export default Users;
